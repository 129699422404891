import React from 'react'
import { useQuery } from '@apollo/client'
import { FinancialStatus, FulfillmentStatus, GetOpenOrders, GetOpenOrdersQuery, GetOpenOrdersQueryVariables, OpenOrder } from './schema'
import { Card, ResourceList, ResourceItem, Layout, Page, Badge, Stack, Heading, Link } from '@shopify/polaris';

function renderItem(order: Partial<OpenOrder>) {
    let statusTest = ''
    let status: 'success' | 'info' | 'attention' | 'critical' | 'warning' | 'new' | null = null
    let progress: 'incomplete' | 'partiallyComplete' | 'complete' | null = null

    if (order.financialStatus === FinancialStatus.Voided) {
        statusTest = 'Voided'
    }
    else if (order.financialStatus === FinancialStatus.Pending) {
        progress = 'incomplete'
        status = 'attention'
        statusTest = 'Unpaid'
    }
    else {
        if (order.fulfillmentStatus === FulfillmentStatus.None) {
            progress ='incomplete'
            status = 'new'
            statusTest = 'Paid'
        }
        else if (order.fulfillmentStatus === FulfillmentStatus.Partial) {
            progress ='partiallyComplete'
            status = 'info'
            statusTest = 'Partially fulfilled'
        }
        else {
            progress ='complete'
            status = 'success'
            statusTest = 'Fullfilled'
        }
    }

    return <ResourceItem
        id={order.id}
        url={`/order/${order.id}`}
    >
        <Stack distribution='equalSpacing'>
            <Heading>{order.number}</Heading>
            <Badge status={status} progress={progress}>{statusTest}</Badge>
        </Stack>
    </ResourceItem>
}

export function OrdersPage() {
    const { loading, error, data } = useQuery<GetOpenOrdersQuery, GetOpenOrdersQueryVariables>(GetOpenOrders)

    if (error) return <p>Error...</p>;

    return <Page title='Orders' fullWidth>
        <Layout>
            <Layout.Section>
                <Card>
                    <ResourceList
                        items={data?.openOrders?.items ?? []}
                        renderItem={renderItem}
                        loading={loading}
                    />
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
}

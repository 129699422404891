import * as React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { OrdersPage } from './orders.page'
import { OrderPage } from './order'
import { BrowserRouter, Route, Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, Loading } from '@shopify/polaris';
import { Provider as AppBridgeProvider, useAppBridge, useClientRouting } from '@shopify/app-bridge-react'
import {NavigationMenu, AppLink} from '@shopify/app-bridge/actions'

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const appBridgeConfig = {
    apiKey: process.env.SHOPIFY_API_KEY,
    host: params['host']
}

interface SyncLinkProps {
    menu: NavigationMenu.NavigationMenu
    link: AppLink.AppLink
    children: React.ReactNode
}

function SyncLink({ menu, link, children }: SyncLinkProps) {
    React.useEffect(() => {
        menu.set({ active: link })
    }, [ ])

    return <>{children}</>
}

export function App() {
    return <AppBridgeProvider config={appBridgeConfig}>
        <Helmet>
            <base href={process.env.PUBLIC_PATH} />
        </Helmet>

        <BrowserRouter basename={process.env.PUBLIC_PATH}>
            <Main />
        </BrowserRouter>
    </AppBridgeProvider>
}

const Main = () => {
    const [ client ] = React.useState(() => {
        const client = new ApolloClient({
            uri: `${process.env.SERVER_URL}/graphql`,
            cache: new InMemoryCache()
        })

        return client
    })

    const navigate = useNavigate()
    useClientRouting({
        replace: path => navigate(path, { replace: true })
    })

    const app = useAppBridge()
    const [ rootNav ] = React.useState(() => {
        const ordersLink = AppLink.create(app, {
            label: 'Order',
            destination: '/',
        })

        const discountsLink = AppLink.create(app, {
            label: 'Discounts',
            destination: '/discounts',
        })

        const menu = NavigationMenu.create(app, {
            items: [ ordersLink, discountsLink ],
            active: ordersLink
        })

        return {
            menu,
            links: {
                ordersLink,
                discountsLink
            }
        }
    })

    return <AppProvider
        i18n={enTranslations}
        linkComponent={({ url, external, download, ...props }) => {
            return <Link {...props as any} to={url} />
        }}>
        <Frame>
            { client
                ? <ApolloProvider client={client}>
                    <Route path='/order/:orderId'>
                        <SyncLink menu={rootNav.menu} link={rootNav.links.ordersLink}>
                            <OrderPage />
                        </SyncLink>
                    </Route>
                    <Route path='/discounts'>
                        <SyncLink menu={rootNav.menu} link={rootNav.links.discountsLink}>
                            <OrderPage />
                        </SyncLink>
                    </Route>
                    <Route path='/'>
                        <SyncLink menu={rootNav.menu} link={rootNav.links.ordersLink}>
                            <OrdersPage />
                        </SyncLink>
                    </Route>
                </ApolloProvider>
                : <Loading />
            }
        </Frame>
    </AppProvider>
}

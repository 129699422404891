import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Color: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  Uuid: any;
};




export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}


export type CreateBatchInput = {
  outlines?: Maybe<Array<OutlineStyle>>;
  shapes?: Maybe<Array<StarmapShape>>;
  galaxyArts?: Maybe<Array<GalaxyArtStyle>>;
  designCode?: Maybe<Scalars['String']>;
  styleCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  styleVariants?: Maybe<Array<StyleVariantCode>>;
  sizeCode?: Maybe<Scalars['String']>;
  centerX: Scalars['Float'];
  centerY: Scalars['Float'];
  originX: Scalars['Float'];
  originY: Scalars['Float'];
  sizeX: Scalars['Float'];
  sizeY: Scalars['Float'];
  margin?: Maybe<Array<Scalars['Float']>>;
  padding?: Maybe<Array<Scalars['Float']>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  orientation: Orientation;
  dateTime: Scalars['DateTime'];
  features?: Maybe<Array<StarmapFeature>>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  quality?: Maybe<CreateQuality>;
};

export type CreateBatchResponse = {
  batchId: Scalars['Uuid'];
};

export type CreateInput = {
  outline: OutlineStyle;
  shape: StarmapShape;
  galaxyArt: GalaxyArtStyle;
  designCode?: Maybe<Scalars['String']>;
  styleCode?: Maybe<Scalars['String']>;
  styleVariant: StyleVariantCode;
  sizeCode?: Maybe<Scalars['String']>;
  centerX: Scalars['Float'];
  centerY: Scalars['Float'];
  originX: Scalars['Float'];
  originY: Scalars['Float'];
  sizeX: Scalars['Float'];
  sizeY: Scalars['Float'];
  margin?: Maybe<Array<Scalars['Float']>>;
  padding?: Maybe<Array<Scalars['Float']>>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  dateTime: Scalars['DateTime'];
  features?: Maybe<Array<StarmapFeature>>;
  orientation: Orientation;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  seed?: Maybe<Scalars['Int']>;
  font?: Maybe<Scalars['String']>;
  quality?: Maybe<CreateQuality>;
};

export enum CreateQuality {
  Low = 'LOW',
  High = 'HIGH'
}

export type CreateResponse = {
  imageUrl?: Maybe<Scalars['String']>;
};


export type Dimensions = {
  width: Scalars['Float'];
  height: Scalars['Float'];
  horizontal?: Maybe<Dimensions>;
};

export enum FinancialStatus {
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  PartiallyPaid = 'PARTIALLY__PAID',
  Paid = 'PAID',
  PartiallyRefunded = 'PARTIALLY__REFUNDED',
  Refunded = 'REFUNDED',
  Voided = 'VOIDED'
}

export type FontPairing = Node & {
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  titleFamily?: Maybe<Scalars['String']>;
  titleScaling: Scalars['Float'];
  textFamily?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type FontPairingConnection = {
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<FontPairingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<FontPairing>>>;
};

/** An edge in a connection. */
export type FontPairingEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FontPairing>;
};

export enum FulfillmentStatus {
  None = 'NONE',
  Partial = 'PARTIAL',
  Fulfilled = 'FULFILLED',
  Restocked = 'RESTOCKED'
}

export enum GalaxyArtStyle {
  Solid = 'SOLID',
  Circles = 'CIRCLES',
  Hearts = 'HEARTS',
  Stars = 'STARS',
  Hatches = 'HATCHES'
}

export type GetOpenOrdersResponse = {
  items?: Maybe<Array<Maybe<OpenOrder>>>;
};


export type Mutation = {
  create?: Maybe<CreateResponse>;
  batch?: Maybe<CreateBatchResponse>;
  processOrder?: Maybe<Order>;
  saveDesign?: Maybe<SaveDesignResponse>;
};


export type MutationCreateArgs = {
  input: CreateInput;
};


export type MutationBatchArgs = {
  input: CreateBatchInput;
};


export type MutationProcessOrderArgs = {
  input: ProcessOrderInput;
};


export type MutationSaveDesignArgs = {
  designId?: Maybe<Scalars['String']>;
  input: CreateInput;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export type OpenOrder = {
  id: Scalars['Long'];
  number?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  amount: Scalars['Long'];
  currency?: Maybe<Scalars['String']>;
  fulfillmentStatus: FulfillmentStatus;
  financialStatus: FinancialStatus;
};

export type Order = {
  id: Scalars['Long'];
  number?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  amount: Scalars['Long'];
  currency?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<StarmapItem>>>;
};

export enum Orientation {
  V = 'V',
  H = 'H'
}

export enum OutlineStyle {
  None = 'NONE',
  Circle = 'CIRCLE',
  Dots = 'DOTS',
  Stars = 'STARS',
  Hearts = 'HEARTS',
  Ticks = 'TICKS',
  Ticks2 = 'TICKS2'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type ProcessOrderInput = {
  orderId: Scalars['Long'];
};

export type Query = {
  node?: Maybe<Node>;
  scenes?: Maybe<Array<Maybe<SceneInfo>>>;
  order?: Maybe<Order>;
  openOrders?: Maybe<GetOpenOrdersResponse>;
  styles?: Maybe<StyleConnection>;
  sizes?: Maybe<SizeConnection>;
  fontPairings?: Maybe<FontPairingConnection>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryOrderArgs = {
  id: Scalars['Long'];
};


export type QueryStylesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QuerySizesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryFontPairingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};

export type SaveDesignResponse = {
  id?: Maybe<Scalars['String']>;
};

export type SceneInfo = {
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Size>;
};

export type Size = Node & {
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  category: SizeCategory;
  metric?: Maybe<Dimensions>;
  imperial?: Maybe<Dimensions>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
  height: Scalars['Float'];
  ratio: Scalars['Int'];
};

export enum SizeCategory {
  Iso = 'ISO',
  European = 'EUROPEAN',
  Us = 'US'
}

/** A connection to a list of items. */
export type SizeConnection = {
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<SizeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Size>>>;
};

/** An edge in a connection. */
export type SizeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Size>;
};

export enum StarmapFeature {
  Galaxy = 'GALAXY',
  Constellations = 'CONSTELLATIONS',
  Stars = 'STARS',
  Grid = 'GRID',
  Frame = 'FRAME'
}

export type StarmapItem = {
  id: Scalars['Long'];
  sku?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  totalPrice: Scalars['Long'];
  product?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  textLayerUrl?: Maybe<Scalars['String']>;
};

export enum StarmapShape {
  None = 'NONE',
  Circle = 'CIRCLE',
  Enlargedcircle = 'ENLARGEDCIRCLE',
  Heart = 'HEART'
}

export type Style = Node & {
  id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  main: Scalars['Boolean'];
  variants?: Maybe<Array<Maybe<StyleVariant>>>;
};

/** A connection to a list of items. */
export type StyleConnection = {
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StyleEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Style>>>;
};

/** An edge in a connection. */
export type StyleEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Style>;
};

export type StyleVariant = {
  code: StyleVariantCode;
  primaryColor?: Maybe<Scalars['Color']>;
  textColor?: Maybe<Scalars['Color']>;
  paperColor?: Maybe<Scalars['Color']>;
  outlineColor?: Maybe<Scalars['Color']>;
  starChartColor?: Maybe<Scalars['Color']>;
  gridColor?: Maybe<Scalars['Color']>;
  constellationsColor?: Maybe<Scalars['Color']>;
  starsColor?: Maybe<Scalars['Color']>;
  galaxyColor?: Maybe<Scalars['Color']>;
  coloredStars: Scalars['Boolean'];
  gridLineWidth: Scalars['Float'];
  constellationLineWidth: Scalars['Float'];
  brightStarLimt: Scalars['Float'];
};

export enum StyleVariantCode {
  Colored = 'COLORED',
  White = 'WHITE'
}


export type GetOpenOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenOrdersQuery = { openOrders?: Maybe<{ items?: Maybe<Array<Maybe<Pick<OpenOrder, 'id' | 'number' | 'date' | 'amount' | 'currency' | 'fulfillmentStatus' | 'financialStatus'>>>> }> };

export type GetOrderQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetOrderQuery = { order?: Maybe<OrderFragment> };

export type ProcessOrderMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type ProcessOrderMutation = { processOrder?: Maybe<OrderFragment> };

export type OrderFragment = (
  Pick<Order, 'id' | 'number' | 'date' | 'amount' | 'currency'>
  & { items?: Maybe<Array<Maybe<StarmapItemFragment>>> }
);

export type StarmapItemFragment = Pick<StarmapItem, 'id' | 'sku' | 'options' | 'product' | 'media' | 'size' | 'quantity' | 'totalPrice' | 'url' | 'previewUrl' | 'textLayerUrl'>;

export const StarmapItem = gql`
    fragment StarmapItem on StarmapItem {
  id
  sku
  options
  product
  media
  size
  quantity
  totalPrice
  url
  previewUrl
  textLayerUrl
}
    `;
export const Order = gql`
    fragment Order on Order {
  id
  number
  date
  amount
  currency
  items {
    ...StarmapItem
  }
}
    ${StarmapItem}`;
export const GetOpenOrders = gql`
    query GetOpenOrders {
  openOrders {
    items {
      id
      number
      date
      amount
      currency
      fulfillmentStatus
      financialStatus
    }
  }
}
    `;
export const GetOrder = gql`
    query GetOrder($id: Long!) {
  order(id: $id) {
    ...Order
  }
}
    ${Order}`;
export const ProcessOrder = gql`
    mutation ProcessOrder($id: Long!) {
  processOrder(input: {orderId: $id}) {
    ...Order
  }
}
    ${Order}`;
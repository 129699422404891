import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'

import '@shopify/polaris/build/esm/styles.css'

const host = document.getElementById('app')
ReactDOM.render(<App />, host)

// Hot Module Replacement API
declare const module: { hot: any };
if (module && module.hot) {
    module.hot.accept()
}

import * as React from 'react'
import { Spinner } from '@shopify/polaris'

// https://alphahydrae.com/2021/02/how-to-display-an-image-protected-by-header-based-authentication/
export async function fetchImage(url: string, accessToken?: string) {
    const headers: HeadersInit = { }
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
    }

    const image = await fetch(url, { headers })

    const blob = await image.blob()
    return blob
}

export function FetchImage({ src, ...props }: React.HTMLProps<HTMLImageElement>) {
    const [ loading, setLoading ] = React.useState(true)
    const [ ref, setRef ] = React.useState<HTMLImageElement>(null)

    React.useEffect(() => {
        if (!ref) return

        if (!src) {
            ref.src = null
            return
        }

        setLoading(true)

        async function downloadImage() {
            const imageBlob = await fetchImage(src)
            const imageUrl = URL.createObjectURL(imageBlob)

            ref.src = imageUrl
            ref.onload = () => {
                URL.revokeObjectURL(imageUrl)
                setLoading(false)
            }
        }

        downloadImage()

    }, [ ref, src ])

    return <div style={{ width: '100%' }}>
        { loading && <Spinner /> }
        <img {...props as any} ref={setRef} style={{ display: loading ? 'none' : null, maxWidth: '100%' }} />
    </div>
}

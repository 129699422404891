import * as React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GetOrder, GetOrderQuery, GetOrderQueryVariables, StarmapItemFragment } from './schema'
import { ProcessOrder, ProcessOrderMutation, ProcessOrderMutationVariables } from './schema'
import { Link, useParams } from 'react-router-dom'
import { fetchImage, FetchImage } from './fetch-image'
import { saveAs } from 'file-saver'
import { Banner, Caption, Card, Heading, Layout, Loading, Page, ResourceItem, ResourceList, Spinner, Stack, TextContainer } from '@shopify/polaris'

interface OrderRouteParams {
    orderId?: string
}

async function downloadImage(item: StarmapItemFragment) {
    const blob = await fetchImage(`${process.env.SERVER_URL}${item.url}`)

    saveAs(blob, `${item.id}-${item.product}.png`)
}

function renderItem(item: StarmapItemFragment) {
    return <ResourceItem
        id={item.id}
        onClick={() => { }}
        media={<FetchImage src={`${process.env.SERVER_URL}${item.previewUrl}`} height='100' />}
        verticalAlignment='center'
        shortcutActions={[
            { content: 'Download', onAction: async () => {
                await downloadImage(item)
            }}
        ]}
        >
        <Stack distribution='equalSpacing' alignment='center'>
            <div>{item.product}</div>
            <div>{(item.totalPrice / 100).toFixed(2)}</div>
        </Stack>
    </ResourceItem>
}

export function OrderPage() {
    const { orderId } = useParams<OrderRouteParams>()
    var id = parseInt(orderId)

    const { loading: orderLoading, error: orderError, data } = useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrder, {
        variables: {
            id
        }
    });

    const [ processOrder, { loading: orderProcessing, error: processError } ] = useMutation<ProcessOrderMutation, ProcessOrderMutationVariables>(ProcessOrder, {
        variables: {
            id
        },
        refetchQueries: [
            'GetOrder'
        ]
    })


    if (orderLoading) return <Loading />

    const order = data?.order
    return <Page
        fullWidth
        title={order?.number ?? ''}
        breadcrumbs={[ { url: '/', content: 'Orders' } ]}
        primaryAction={{ content: 'Process', disabled: !order || orderProcessing, onAction: processOrder }}
        >
        <Layout>
            { orderError && <Layout.Section>
                <Banner status='critical' title='Cannot load order details'>
                    <p>{ orderError.message }</p>
                </Banner>
            </Layout.Section> }

            { processError && <Layout.Section>
                <Banner status='critical' title='Order was not processed'>
                    <p>{ processError.message }</p>
                </Banner>
            </Layout.Section> }

            { orderProcessing && <Layout.Section>
                <Spinner />
            </Layout.Section> }

            { order && <>
                <Layout.Section>
                    <Card>
                        <ResourceList
                            items={order?.items ?? []}
                            renderItem={renderItem}
                        />
                    </Card>
                </Layout.Section>
            </> }
        </Layout>
    </Page>
}

interface OrderItemProps {
    item: StarmapItemFragment
}

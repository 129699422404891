"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Picker_1 = require("@shopify/app-bridge/actions/Picker");
var useAppBridge_1 = require("../../useAppBridge");
function Picker(props) {
    var app = useAppBridge_1.useAppBridge();
    var prevProps = react_1.useRef({ open: false });
    var picker = react_1.useMemo(function () { return Picker_1.create(app, getActionOptions(props)); }, [app]);
    react_1.useEffect(function () {
        var _a;
        if (!props.open && ((_a = prevProps.current) === null || _a === void 0 ? void 0 : _a.open) === props.open) {
            return;
        }
        if (props.open) {
            picker.dispatch(Picker_1.Action.OPEN);
        }
        else {
            picker.dispatch(Picker_1.Action.CANCEL);
        }
    }, [props.open]);
    react_1.useEffect(function () {
        var unsubscribeList = [];
        if (props.onSelect) {
            unsubscribeList.push(picker.subscribe(Picker_1.Action.SELECT, props.onSelect));
        }
        if (props.onCancel) {
            unsubscribeList.push(picker.subscribe(Picker_1.Action.CANCEL, props.onCancel));
        }
        if (props.onSearch) {
            unsubscribeList.push(picker.subscribe(Picker_1.Action.SEARCH, props.onSearch));
        }
        if (props.onLoadMore) {
            unsubscribeList.push(picker.subscribe(Picker_1.Action.LOAD_MORE, props.onLoadMore));
        }
        return function () {
            unsubscribeList.forEach(function (unsubscribe) { return unsubscribe(); });
        };
    }, [props.onSelect, props.onCancel, props.onSearch]);
    react_1.useEffect(function () {
        var shouldUpdate = JSON.stringify(__assign(__assign({}, prevProps.current), { open: undefined })) !==
            JSON.stringify(__assign(__assign({}, props), { open: undefined }));
        if (!shouldUpdate) {
            return;
        }
        picker.set(getActionOptions(props));
        prevProps.current = props;
    }, [props]);
    return null;
}
exports.default = Picker;
function getActionOptions(_a) {
    var items = _a.items, selectedItems = _a.selectedItems, maxSelectable = _a.maxSelectable, title = _a.title, loading = _a.loading, searchQueryPlaceholder = _a.searchQueryPlaceholder, searchQuery = _a.searchQuery, primaryActionLabel = _a.primaryActionLabel, secondaryActionLabel = _a.secondaryActionLabel, emptySearchLabel = _a.emptySearchLabel;
    var result = {
        items: items,
        selectedItems: selectedItems,
        maxSelectable: maxSelectable,
        title: title,
        loading: loading,
        searchQueryPlaceholder: searchQueryPlaceholder,
        searchQuery: searchQuery,
        primaryActionLabel: primaryActionLabel,
        secondaryActionLabel: secondaryActionLabel,
        emptySearchLabel: emptySearchLabel,
    };
    return result;
}
